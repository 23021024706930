import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services';
import { environment } from "../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        var currentTime = new Date().getTime();
        var token = null;
        if(localStorage.getItem('token')!=undefined){
            token = localStorage.getItem('token');
        }
        
        if(request.url.includes('tenants/basic-info/') || request.url.includes('/reset-password/') || request.url.includes('/do-reset-password/')){
            request = request.clone({
                headers: new HttpHeaders({
                })
            });
        }
        else if(request.url.includes('/jumeirah/')){
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ token,
                    'x-api-key' : environment.xapikey,
                    'Cache-control': "private",
                })
            });
        }
        else
        {
            request = request.clone({
                headers: new HttpHeaders({
                    'Authorization':  'JWT '+ token,
                })
            });
        }
        return next.handle(request);
    }
}