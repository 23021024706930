import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { WebService } from "../../services/web.service";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {
	FormControl,
	FormGroup,
	FormBuilder,
	FormsModule,
	NgForm
} from "@angular/forms";
@Component({
  	selector: 'app-login',
  	templateUrl: './login.component.html',
  	styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    @ViewChild('modalloader')
        private modaloader: TemplateRef<any>;
    @ViewChild('modalsuccessconfirmation')
        private successPopup: TemplateRef<any>;
	email: string = '';
	password: string = '';
	emailpattern: string = "^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9-\+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$";
	incorrect_details: boolean = false;
	error_msg: string = '';
    forgotPassword: boolean = false;
    passwordReset: boolean = false;
    new_password: string = '';
    confirm_password: string = '';
    queryParams : any ;
    modalRef: any;
  	constructor(private modalService: NgbModal, private webService: WebService, private route: ActivatedRoute) { }
  	private gravtyhost = environment.gravtyhost();
  	ngOnInit() {
          this.route.queryParams.subscribe(queryParams => {
            if(this.queryParams==undefined){
                this.queryParams = queryParams;
            }
            else if(this.queryParams!= queryParams){
                location.reload();
            }
        });
        this.tenantInfoCheck();
        this.expirationCheck();
        if (this.getParam('email') != 0 && this.getParam('reset_key')!=0){
            this.passwordReset = true;
        }
        
  	}

    showPopup(modalid, modalclass) {
        this.open(modalid, modalclass)
    }

    open(content, modalclass) {
        this.modalRef = this.modalService.open(content, { windowClass: modalclass });
    }

    close() {
        this.modalRef.close();
    }

  	login() {
        localStorage.removeItem('tokenTimeOut');
        localStorage.removeItem('token');
  		this.incorrect_details = false;
  		var req: any ={
      		password: this.password,
      		username: this.email
    	}
    	var requestUrl = this.gravtyhost + 'login/';
        this.open(this.modaloader,'modalloader');
    	this.webService.postAPI(requestUrl, req, false).then((data: any) => {
      		localStorage.setItem('token',data.token);
            localStorage.setItem('currentUser',JSON.stringify(data.user));
            localStorage.setItem('login_response',JSON.stringify(data));
      		var loginTime = new Date().getTime();
      		var tokenExpiry = loginTime + (data.expires_in)*1000;
      		localStorage.setItem('tokenTimeOut',tokenExpiry.toString());
            this.close();
            var url = window.location.href;
            url = url.replace("login", "");
            window.location.href = url;
    	})
    	.catch(error => {
            this.close();
          	if(error.error){
          		this.incorrect_details = true;
          		if(error.error.status_code === 400){
	          		this.error_msg = 'Incorrect login details';
	          	}
	          	else{
	          		this.error_msg = error.error.message;
	          	}
          	}
        });
  	}

    clearError() {
        this.incorrect_details = false;
        this.error_msg = "";
    }

    getParam(name){
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if(!results){
          return 0;
        }
        return results[1] || 0;
    }

    reload(){
        var url= window.location.href;
        var path = url.split('/#/');
        window.location.href= path[0] + '/#/login';
        location.reload();
    }

    newPasswordSubmit() {
        if(this.new_password !== this.confirm_password){
            this.incorrect_details = true;
            this.error_msg = 'Passwords do not match';
            return;
        }
        this.incorrect_details = false;
        var req: any = {
            email: this.getParam('email'),
            reset_key: this.getParam('reset_key'),
            new_password: this.new_password,
            confirm_password: this.confirm_password
        }
        req.email = req.email.replace('%20', '+');
        let _self = this;
        var requestUrl = this.gravtyhost + "accounts/do-reset-password/";
        this.open(this.modaloader,'modalloader');
        this.webService.postAPI(requestUrl, req, false).then((data: any) => {
            this.close();
            localStorage.setItem('success','Password reset successfully');
            this.showPopup(this.successPopup,'modalsuccessconfirmation');
            setTimeout(function(){ _self.close(); }, 2000);
            this.forgotPassword = false;
            this.passwordReset = false;
        })
        .catch(error => {
            this.close();
            this.incorrect_details = true;
            if(error.error.message){
                this.error_msg = error.error.message;
            }
            else if(error.error[0] && error.error[0].message){
                this.error_msg = error.error[0].message;
            }
        });
    }

    reset(){
        var req: any ={
            email: this.email
        }
        this.incorrect_details = false;
        var requestUrl = this.gravtyhost + 'accounts/reset-password/';
        let _self = this;
        this.open(this.modaloader,'modalloader');
        this.webService.postAPI(requestUrl, req, false).then((data: any) => {
            this.close();
            localStorage.setItem('success','Mail sent successfully');
            this.showPopup(this.successPopup,'modalsuccessconfirmation');
            setTimeout(function(){ _self.close(); }, 2000);
            this.forgotPassword = false;
        })
        .catch(error => {
            this.close();
            this.incorrect_details = true;
            this.error_msg = error.error.message;
        });
    }

    tenantInfoCheck() {
        if(localStorage.getItem('tenantInfo')==undefined || localStorage.getItem('tenantInfo')==null){
            var tenantInfoUrl = this.gravtyhost + 'tenants/basic-info/';
            this.webService.getAPI(tenantInfoUrl, false).then((data: any) => {
              localStorage.setItem('tenantInfo',JSON.stringify(data));
            });
        }
    }

    expirationCheck() {
        var currentTime = new Date().getTime();
        var tokentimeout;
        if(localStorage.getItem('tokenTimeOut')==undefined || localStorage.getItem('tokenTimeOut')==null){
            return;
        }
        else{
            tokentimeout = parseInt(localStorage.getItem('tokenTimeOut'));
        }
        if((tokentimeout-100000) >= currentTime) {
            var url = window.location.href;
            if(url.includes('reset_key')){
                localStorage.removeItem('tokenTimeOut');
                localStorage.removeItem('token');
            }
            else{
                url = url.replace("login", "");
                window.location.href = url;
            }
        }
        else{
            localStorage.clear();
        }
    }

}
