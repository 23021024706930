import { Component, OnInit, Input, Output, ViewChild, EventEmitter, TemplateRef } from '@angular/core';
import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { CommonService, MemberService } from '../../services';
import * as moment from 'moment-timezone';
import {timer} from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-paywithpoints',
	templateUrl: './paywithpoints.component.html',
	styleUrls: ['./paywithpoints.component.less']
})
export class PaywithpointsComponent implements OnInit {
	@ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
	@Input() memberData: any;
	@Input() sponsor_details: any;
	@Input() redeemFlag: boolean;
	@Input() conversion_rate: any;
	@Input() currency: any;
	@Output() payWithPointsClose = new EventEmitter();
	@Output() virtualReload = new EventEmitter();
	tab: string = "redeemrewards";
	error: boolean = false;
	errorMsg: string = "";
	invoiceDetails: any = [];
	totalPoints: number = 0;
	totalBal: number = 0;
	totalBalance: number = 0;
	totalPts: number =0;
	remainingBal: number = 0;
	remainingPts: number = 0;
	confirmation; redemption;
	consume: boolean = false;
	awardOffers: any = [];
	searchval: string = "";
	offerData: any;
	loader: boolean = false;
	privileges: any = [];
	privsearchval: string = "";
	pointsConversion: any = [];
	modalRef: any;
	paymentData: any = {
		'data': []
	};
	filteredPrivileges: any = [];
	filteredAwardOffers: any = [];
	profileId: string;
	resort: string;
	reservation_id: string;
	confirmation_number: string;
	allSelected: boolean = true;
	pointsvalueInvoiceCurrency: any = 0;
	invoices:boolean = false;
	invoicesError:boolean = false;
	reservationProfileCheck: boolean = true;
	arrivalDate: string = '';
	departureDate: string = '';
	roomNumber: string = '';
	positiveBills: number = 0;
	selectedBills: number = 0;
	locations: any = [];
	dueError_count: number = 0;
	due_error_msg: string = 'Amount is greater than Due Amount.';
	pointsIndex: number = 0;
	queryParams: any;
	memberId: string = '';
	FFP_data: any;
	bitDetails: any;
	constructor(
		private modalService: NgbModal,
		private memberService: MemberService,
		private commonService: CommonService,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this.route.queryParams.subscribe(queryParams => {
	        if(this.queryParams==undefined){
	            this.queryParams = queryParams;
	        }
	        else if(this.queryParams!= queryParams){
	            location.reload();
	        }
	    });
	    this.resort = this.sponsor_details.short_name;
	    this.memberId = this.commonService.getParam('id').toString();
		if(this.memberData.balances.length>0){
			this.pointsIndex = parseInt(localStorage.getItem('pointsIndex'));
			this.pointsvalueInvoiceCurrency = this.memberData.balances[this.pointsIndex].balance_currency_amount;
		}
		if(this.redeemFlag){
			this.getAwardOffers();
		}
		else{
			this.tab = "consumerewards";
			this.getPrivileges();
		}
	}

	closePopup(x) {
		this.modalRef.close();
		if(x==2){
			return;
		}
		if(x==1){
			this.tab = "consumerewards";
			this.getPrivileges();
		}
		if(x==0){
			this.open(this.modaloader,'modalloader');
			this.memberService
				.getMemberDataWithId(this.memberData.member_id)
				.then((data: any) => {
					this.modalRef.close();
					this.memberData = data.data;
					if(this.tab==='redeemrewards'){
						if(this.memberData.balances.length>0){
							this.memberData.balances[this.pointsIndex].balance = parseInt(this.memberData.balances[this.pointsIndex].balance.toFixed(0));
	                        this.memberData.balances[this.pointsIndex].balance_currency_amount = parseFloat(((this.memberData.balances[this.pointsIndex].balance) * (this.conversion_rate)).toFixed(2));
	                        this.memberData.balances[this.pointsIndex].balance_currency = this.currency;
							this.pointsvalueInvoiceCurrency = this.memberData.balances[this.pointsIndex].balance_currency_amount;
						}
						this.getAwardOffers();
					}
				})
		}
	}

	close() {
		this.payWithPointsClose.emit(0);
	}

	omit_char(event)
	{   
	   var k;  
	   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
	   return( k == 46 || (k >= 48 && k <= 57)); 
	}

	BalanceUpdate(invoice,flag) {
		var x;
		if(flag==0){
			x = (<HTMLInputElement>document.getElementById('window_'+invoice.window_number)).value
		}
		else{
			x = invoice.inputAmount;
		}

		if(flag===1 && invoice.selected && invoice.dueError){
			this.dueError_count++;
		}
		if(flag===1 && !invoice.selected && invoice.dueError){
			this.dueError_count--;
		}
		if(flag===3 && !invoice.inputAmount || invoice.inputAmount[0]==='-'){
			invoice.inputAmount = 0;
			x = '0';
		}
		if(flag === 0 && x===''){
			x='0';
		}
		var amount = parseFloat(x);

		if(!invoice.dueError && amount > invoice.current_balance){
			invoice.dueError = true;
			this.dueError_count++;
		}
		if(invoice.dueError){
			if(amount <= invoice.current_balance){
				invoice.dueError = false;
				this.dueError_count--;
			}
		}
		var pts = parseFloat((parseFloat(x)/this.conversion_rate).toString());
		invoice.Points = parseInt((parseFloat(x)/this.conversion_rate).toString());
		if(pts-invoice.Points >= 0.5){
			invoice.Points += 1;
		}
		this.totalBal = 0;
		this.totalPoints = 0;
		for(var i=0;i<this.invoiceDetails.length;i++){
			if(this.invoiceDetails[i].selected){
				if(invoice.window_number == this.invoiceDetails[i].window_number){
					this.totalBal += parseFloat(x);
				}
				else{
					this.totalBal += parseFloat(this.invoiceDetails[i].inputAmount);
				}
				this.totalPoints += this.invoiceDetails[i].Points;
			}
		}
	}

	showPaymentConfirmation(paymentconfirmation, value) {
		switch (value) {
			case "confirmation": {
				this.redemption = false;
				this.consume = false;
				this.confirmation = true;
				var req = {
					"window_number": '',
					'amount': 0,
					"redemption_bit_id": '',
					"resort": this.resort,
					"resv_name_id": this.reservation_id
				};
				this.paymentData['data'] = [];
				for(var i=0;i<this.invoiceDetails.length;i++){
					var obj = JSON.parse(JSON.stringify(req));
					if(this.invoiceDetails[i].selected){
						obj['window_number'] = this.invoiceDetails[i].window_number;
						obj['amount'] = parseFloat(this.invoiceDetails[i].inputAmount);
						this.paymentData['data'].push(obj);
					}
				}
				break;
			}
			case "redemption": {
				this.redemption = true;
				this.consume = false;
				this.confirmation = false;
				this.totalPoints = this.offerData.productAwardPrice;
				break;
			}
			case "consume": {
				this.redemption = false;
				this.consume = true;
				this.confirmation = false;
				break;
			}
			default:
				break;
		}
		this.open(paymentconfirmation, 'modalpaymentconfirmation');
	}

	getAwardOffers() {
		this.loader = true;
		this.memberService.getAllSponsorLocations()
			.then((data: any) => {
				this.locations = data;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
		this.memberService
			.getAwardOffers(this.memberData.member_id)
			.then((data: any) => {
				this.awardOffers = [];
				var tomorrow = new Date();
				tomorrow.setDate(new Date().getDate()+1);
				var nextDay = moment(tomorrow, 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z");
				for(var i=0;i<data.data.length;i++){
					if((data.data[i].expiryDate > nextDay || !data.data[i].expiryDate) && data.data[i].productAwardPrice && data.data[i].productAwardPrice>0){
						this.awardOffers.push(data.data[i]);
					}
				}
				this.filterAwardOffers();
				this.loader = false;
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
	}

	filterAwardOffers() {
		this.filteredAwardOffers = [];
		var str = '';
		var substr = this.searchval.toLowerCase();
		var eligible_locations = [];
		for(var i=0;i<this.awardOffers.length;i++){
			str = this.awardOffers[i].offerName.toLowerCase();
			eligible_locations = [];
			if(this.awardOffers[i].offerExtraData && this.awardOffers[i].offerExtraData.eligible_sponsors){
				eligible_locations = this.awardOffers[i].offerExtraData.eligible_sponsors.replace(/ /g, "").split('|');
			}
			if(str.indexOf(substr) !== -1 && (this.sponsor_details.id===this.awardOffers[i].sponsorID || (eligible_locations.indexOf(this.resort) !== -1))){
				this.filteredAwardOffers.push(this.awardOffers[i]);
			}
		}
	}

	showOfferDetails(index, paymentconfirmation) {
		this.offerData = this.filteredAwardOffers[index];
		this.showPaymentConfirmation(paymentconfirmation, 'redemption');
	}

	open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}

	filterprivileges() {
		this.filteredPrivileges = [];
		var str = '';
		var substr = this.privsearchval.toLowerCase();
		var privCode = '';
		var eligible_locations = [];
		for(var i=0;i<this.privileges.length;i++){
			str = this.privileges[i].offerName.toLowerCase();
			eligible_locations = [];
			if(this.privileges[i].offerExtraData && this.privileges[i].offerExtraData.eligible_sponsors){
				eligible_locations = this.privileges[i].offerExtraData.eligible_sponsors.replace(/ /g, "").split('|');
			}
			privCode = this.privileges[i].uniquePrivilegeCode.toLowerCase();
			if((str.indexOf(substr) !== -1 || privCode.indexOf(substr)!== -1) && (this.privileges[i].sponsorID === this.sponsor_details.id || (eligible_locations.indexOf(this.resort) !== -1)) && (!this.privileges[i].offerExtraData || !this.privileges[i].offerExtraData.award_currency || (this.sponsor_details.extra_data && this.sponsor_details.extra_data.sponsor_currency === this.privileges[i].offerExtraData.award_currency))){
				this.filteredPrivileges.push(this.privileges[i]);
			}
		}
	}

	getPrivileges() {
		this.loader = true;
		this.memberService
			.getPrivileges(this.memberData.member_id)
			.then((data: any) => {
				this.privileges = [];
				for (let i = 0; i < data.data.length; i++) {
					if(data.data[i].status==='AVAILABLE'){
						this.privileges.push(data.data[i]);
						if(data.data[i].offerExtraData && data.data[i].offerExtraData.multiple_usage === 'Y'){
							for(var j=i+1;j<data.data.length;j++){
								if(data.data[j].offerExtraData && data.data[j].offerExtraData.multiple_usage === 'Y' && data.data[j].productName === data.data[i].productName && data.data[j].offerName === data.data[i].offerName){
									data.data.splice(j, 1);
									j--;
								}
							}
						}
					}	
				}
				this.loader = false;
				this.filterprivileges();
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
	}

	showPrivilegeDetails(index, paymentconfirmation) {
		this.offerData = this.filteredPrivileges[index];
		this.bitDetails = [];
		if(this.offerData.productID == 262) {
			this.memberService
			.getBItDetail(this.offerData.memberID, this.offerData.bitID)
			.then((data: any) => {
				this.bitDetails = data;
				this.showPaymentConfirmation(paymentconfirmation, 'consume');
			})
			.catch(error => {
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
				this.loader = false;
			});
		} else {
			this.showPaymentConfirmation(paymentconfirmation, 'consume');
		}

	}
}
