import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { WebService } from "../../services/web.service";
import { environment } from "../../environments/environment";
import { MemberService } from '../../services/member.service';
import { Router, ActivatedRoute } from '@angular/router';
import {timer} from 'rxjs';
import * as moment from 'moment-timezone';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
	@ViewChild('modalloader')
        private modaloader: TemplateRef<any>;
    @ViewChild('modalprivDetails')
        private privDetails: TemplateRef<any>;
    @ViewChild('modalsuccessconfirmation')
        private successPopup: TemplateRef<any>;
    @ViewChild('modalpaywithpoints')
        private modalpaywithpoints: TemplateRef<any>;
	code: string = '';
    error_msg: string = '';
    shortName: string = '';
    incorrect_code: boolean = false;
    modalRef: any;
    successful: boolean = false;
    sponsor_details: any = {
        id: null,
        name: '',
        short_name: '',
        extra_data: {}
    };
    locations: any;
    comment: string = '';
    consumeLocation: string = '';
    processing_date: string = '';
    privilege_value: string = '0';
    queryParams : any ;
    user: any;
    login_response: any;
    memberId: string = "";
    offerData: any;
    error: boolean = false;
    h_program_id: number = null;
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    memberList: any = [];
    searched: boolean = false;
    enrolFlag: boolean = false;
    enrolment_code: any;
    previousUrl: string = null;
    nextUrl: string = "None";
    can_view_member: boolean = true;
    can_edit_member: boolean = true;
    can_view_member_details: boolean = true;
    can_edit_bit: boolean = true;
    options: boolean = true;
    searchMember: boolean = false;
    manageRewards: boolean = false;
    redeemFlag: boolean = false;
    pointsIndex: number = 0;
    pointsConversion: any = [];
    currency: any;
    conversion_rate: any;
    public memberData: any;
  	constructor(private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private webService: WebService, private memberService: MemberService) { }
  	private gravtyhost = environment.gravtyhost();
    ngOnInit() {
    	this.modalService.dismissAll();
        var url= window.location.href;
        var path = url.split('/#/');
        if(window.location.href != path[0] + '/#/'){
            window.location.href= path[0] + '/#/';
            location.reload();
        }
        this.expirationCheck();
        var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
        if(tenantInfo){
          this.h_program_id = tenantInfo.id;
        }
        this.user = JSON.parse(localStorage.getItem('currentUser'));
        this.login_response = JSON.parse(localStorage.getItem('login_response'));
        this.sponsor_details.id = this.login_response.sponsor_id;
        this.sponsor_details.name = this.login_response.sponsor_name;
        this.can_view_member = this.login_response.role.can_view_member;
        this.can_edit_member = this.login_response.role.can_edit_member;
        this.can_view_member_details = this.login_response.role.can_view_member_details;
        this.can_edit_bit = this.login_response.role.can_edit_bit;
        this.sponsorDetails();
        this.getCurrencyConversionList();
    }

  	open(content, modalclass) {
        this.modalRef = this.modalService.open(content, { windowClass: modalclass });
    }

    closePopup(x) {
        if(x===0){
            this.memberId = "";
            this.code = '';
            this.modalService.dismissAll();
        }
    }

    getCurrencyConversionList() {
        this.memberService
            .getPointsConversionList()
            .then((data: any) => {
                this.pointsConversion = data;
            });
    }

    sponsorDetails() {
        this.memberService
        .getSponsorDetailsById(this.sponsor_details.id)
        .then((data: any) => {
            this.sponsor_details.name = data.name;
            this.sponsor_details.id = data.id;
            this.sponsor_details.short_name = data.short_name;
            this.sponsor_details.extra_data = data.extra_data;
        });
    }

    close() {
        if(this.modalRef){
            this.modalRef.close();
        }
    }

    getMemberList(modalid, modalclass){
        this.searched = true;
        if(!this.can_view_member){
            return;
        }
        this.open(this.modaloader,'modalloader');
        this.memberService.getMemberList(this.firstName, this.lastName, this.memberId, this.email).then((data: any) => {
            this.close();
            this.memberList = data.body;
            if(this.manageRewards){
                if(this.memberList.length>0){
                    this.memberData = this.memberList[0];
                    this.currency = 'USD';
                    if(this.sponsor_details.extra_data && this.sponsor_details.extra_data.sponsor_currency){
                        this.currency = this.sponsor_details.extra_data.sponsor_currency;
                    }
                    var obj;
                    for(var j=0;j<this.pointsConversion.length;j++){
                        obj = this.pointsConversion[j];
                        if(obj.loyalty_account == 1 && obj.policy_type == 'Award' && obj.currency_code == this.currency){
                            this.conversion_rate = obj.conversion_rate;
                        }
                    }
                    var balance = this.memberData.balances;
                    if(balance!==undefined && balance.length>0){
                        for (var x = 0; x < balance.length; x++) {
                            if (balance[x].loyalty_account == "Points") {
                                localStorage.setItem('pointsIndex',x.toString());
                                this.pointsIndex = x;
                            }
                        }
                        this.memberData.balances[this.pointsIndex].balance = parseInt(this.memberData.balances[this.pointsIndex].balance.toFixed(0));
                        this.memberData.balances[this.pointsIndex].balance_currency_amount = parseFloat(((this.memberData.balances[this.pointsIndex].balance) * (this.conversion_rate)).toFixed(2));
                        this.memberData.balances[this.pointsIndex].balance_currency = this.currency;
                    }
                    this.showPopup(modalid, modalclass);
                }
            }
            var headers = data.headers.headers;
            headers.forEach((value: any, key: any) => {
                if(key==='next'){
                    this.nextUrl = value[0];
                }
                if(key==='prev'){
                    this.previousUrl = value[0];
                }
            });
        })
        .catch(error => {
            this.close();
            this.memberList = [];
            if(error.error && error.error.error){
                this.incorrect_code = true;
                this.error_msg = error.error.error.message;
            }
        });
    }

    setSearchMember(){
        this.options = false;
        this.searchMember = true;
        this.manageRewards = false;
    }

    setManageRewards(){
        this.options = false;
        this.searchMember = false;
        this.manageRewards = true;
    }

    setOptions(){
        this.options = true;
        this.searchMember = false;
        this.manageRewards = false;
    }

    showFilteredMemberList(url){
        this.open(this.modaloader,'modalloader');
        this.memberService.getFilteredMemberList(url).then((data: any) => {
            this.close();
            this.memberList = data.body;
            var headers = data.headers.headers;
            headers.forEach((value: any, key: any) => {
                if(key==='next'){
                    this.nextUrl = value[0];
                }
                if(key==='prev'){
                    this.previousUrl = value[0];
                }
            });
        })
        .catch(error => {
            this.close();
            this.memberList = [];
            if(error.error && error.error.error){
                this.incorrect_code = true;
                this.error_msg = error.error.error.message;
            }
        });
    }

    changePath(path){
        if(!this.can_view_member_details && path.indexOf('member?id') === 0){
            return;
        }
        if(this.enrolment_code){
            localStorage.setItem('enrolment_code',this.enrolment_code);
        }
        else{
            localStorage.removeItem('enrolment_code');
        }
    	window.location.href = window.location.href + path;
    }

    showPopup(modalid, modalclass) {
        this.open(modalid, modalclass)
    }

    showDetails(){
        this.open(this.privDetails,'modalprivDetails');
    }

    redeem() {
        this.expirationCheck();
        let req = {
            "h_bit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z"),
            "h_bit_category": "AVAILMENT",
            "h_sponsor_id": this.sponsor_details.id,
            "h_program_id": this.h_program_id,
            "h_location":this.consumeLocation,
            "h_comment":this.comment,
            "h_bit_source_generated_id":this.memberId,
            "h_unique_privileges": [this.code]
        };
        this.open(this.modaloader,'modalloader');
        this.memberService.createBit(req).then((data: any) => {
            this.close();
            this.processing_date = data.processing_date;
            for(var i=0;i<data.offer_actions.length;i++){
                if(data.offer_actions[i].type==='AVAIL_PRIVILEGE'){
                    this.privilege_value = data.offer_actions[i].value_n;
                }
            }
            this.code = '';
            this.successful = true;
        })
        .catch(error => {
            this.close();
            if(error.error){
                this.incorrect_code = true;
                this.error_msg = error.error.message;
            }
        });
    }

    redeemAward(modalid, modalclass){
        this.redeemFlag = true;
        this.getMemberList(modalid, modalclass);
    }

    consumeReward(modalid, modalclass){
        this.redeemFlag = false;
        this.getMemberList(modalid, modalclass);
    }

    clear() {
        this.consumeLocation = '';
        this.comment = '';
        this.code = '';
        this.memberId = '';
    }

    logout() {
        localStorage.removeItem('tokenTimeOut');
        localStorage.removeItem('token');
        var urllist = window.location.href.split('/#/');
        var url = urllist[0];
        url += '/#/login';
        window.location.href = url;
    }

    expirationCheck() {
        var currentTime = new Date().getTime();
        var tokentimeout;
        if(localStorage.getItem('tokenTimeOut')==undefined || localStorage.getItem('tokenTimeOut')==null){
            var urllist = window.location.href.split('/#/');
            var url = urllist[0];
            url += '/#/login';
            window.location.href = url;
            location.reload();
            return;
        }
        else{
            tokentimeout = parseInt(localStorage.getItem('tokenTimeOut'));
        }
        if((tokentimeout-100000) < currentTime) {
            var urllist = window.location.href.split('/#/');
            var url = urllist[0];
            url += '/#/login';
            window.location.href = url;
            location.reload();
            return;
        }
    }

}
