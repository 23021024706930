import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.less']
})
export class SuccessComponent implements OnInit {
  message: string = '';
  constructor() { }

  ngOnInit() {
  	this.message = localStorage.getItem('success');
  }

}
