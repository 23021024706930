import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { CommonService, MemberService } from '../../services';
import * as moment from 'moment-timezone';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-paymentconfirmation',
  templateUrl: './paymentconfirmation.component.html',
  styleUrls: ['./paymentconfirmation.component.less']
})
export class PaymentconfirmationComponent implements OnInit {
  @ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
  paymentconfirmation: boolean = true;
  @Output() valueChange = new EventEmitter();
  @Input() redemption: boolean = true;
  @Input() confirmation: boolean = false;
  @Input() invoiceCurrency: string = '';
  @Input() allLocations: any = [];
  @Input() consume: boolean = true;
  @Input() consumption: boolean = true;
  @Input() balance: number = 0;
  @Input() points: number = 0;
  @Input() remainingBal: number = 0;
  @Input() remainingPts: number = 0;
  @Input() memberData: any;
  @Input() offerData: any;
  @Input() paymentData: any = {};
  @Input() pointsvalueInvoiceCurrency: number = 0;
  @Input() sponsor_details: any;
  @Input() arrivalDate: string = '';
  @Input() departureDate: string = '';
  @Input() room: string = '';
  @Input() confirmation_number: string = '';
  @Input() bitDetails: any = [];
  error: boolean = false;
  errorMsg: string = "";
  bitData: any;
  invoiceNum: string = "";
  userNotes: string = "";
  confirmationmodal: boolean = false;
  currency: string ='';
  pointBalance: any;
  pointsValue: any;
  modalRef: any;
  AwardConfirmation: boolean = false;
  redemption_bit_id: string = '';
  windowWiseAmount: any = [];
  locations: any;
  consumeLocation: string = '';
  consumeLoader: boolean = false;
  reservation_id: string = '';
  rewardIDs: string = '';
  user: string = '';
  settlePayments: any = [];
  resort: string = '';
  h_program_id = null;
  pointsIndex: number = 0;
  login_response: any;
  can_edit_bit: boolean = true;
  constructor(
    private modalService: NgbModal,
    private memberService: MemberService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.reservation_id = this.commonService.getParam('r').toString();
    this.user = this.commonService.getParam('u').toString();
    this.resort = this.commonService.getParam('p').toString();
    if(this.user === '0'){
      this.user = '';
    }
    if(this.memberData.balances.length===0){
      this.currency = '';
      this.pointsValue = 0;
      this.pointBalance = 0;
    }
    else{
      this.pointsIndex = parseInt(localStorage.getItem('pointsIndex'));
      this.currency = this.memberData.balances[this.pointsIndex].balance_currency;
      this.pointsValue = this.memberData.balances[this.pointsIndex].balance_currency_amount;
      this.pointBalance = this.memberData.balances[this.pointsIndex].balance;
    }
    this.remainingPts = parseInt((this.pointBalance - this.points).toFixed(0));
    this.remainingBal = parseFloat((this.pointsvalueInvoiceCurrency - this.balance).toFixed(2));

    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    if(tenantInfo){
      this.h_program_id = tenantInfo.id;
    }
    this.login_response = JSON.parse(localStorage.getItem('login_response'));
    this.can_edit_bit = this.login_response.role.can_edit_bit;
    
    if(this.consume){
      this.consumeLoader = true;
      this.memberService
      .getSponsorLocations(this.sponsor_details.id)
      .then((data: any) => {
        this.consumeLoader = false;
        this.locations = data;
        this.locations.sort((a, b) => (a.location_name > b.location_name) ? 1 : -1);
      })
      .catch(error => {
        this.consumeLoader = false;
        this.error = true;
        this.errorMsg = "Failed to fetch locations";
      });

    }

    if(this.allLocations.length>0){
      var str = this.offerData.offerExtraData.eligible_locations;
      if(!str){
        this.offerData.eligible_location_names = 'All';
        return;
      }
      var eligible_locations = [];
      if(str.length>0){
        eligible_locations = str.split(' | ');
      }
      var locationNames = '';
      for(var i=0;i<eligible_locations.length;i++){
        for(var j=0;j<this.allLocations.length;j++){
          if(eligible_locations[i] === this.allLocations[j].location_code){
            if(locationNames === ''){
              locationNames = this.allLocations[j].location_name;
            }
            else{
              locationNames += ', ';
              locationNames += this.allLocations[j].location_name;
            }
          }
        }
      }
      this.offerData.eligible_location_names = locationNames;
    }
    console.log('bitDetails',this.bitDetails);
  }

  close() {
    this.valueChange.emit(0);
  }

  cancel() {
    this.valueChange.emit(2);
  }

  open(content, modalclass) {
    this.modalRef = this.modalService.open(content, { windowClass: modalclass });
  }

  closeloader() {
    this.modalRef.close();
  }

  closePrivilege() {
    this.valueChange.emit(1);
  }

  redeemConfirmation() {
    this.error = false;
    if(!this.can_edit_bit){
      this.error = true;
      this.errorMsg = 'You do not have the permission to perform this action';
      return;
    }
    this.AwardConfirmation = true;
    this.paymentconfirmation = true;
    this.redemption = false;
    if(this.memberData.balances.length==0){
      this.remainingPts = -this.offerData.productAwardPrice;
    }
    else{
      this.remainingPts = this.memberData.balances[this.pointsIndex].balance - this.offerData.productAwardPrice;
    }
  }

  convertPointsToCurrency() {
    var flag = false;
    for(var i=0;i<this.paymentData.data.length;i++){
      flag = false;
      for(var j=0;j<this.windowWiseAmount.length;j++){
        if(this.windowWiseAmount[j].window_number === this.paymentData.data[i].window_number){
          this.windowWiseAmount[j].amount += this.paymentData.data[i].amount;
          this.windowWiseAmount[j].amount = parseFloat(this.windowWiseAmount[j].amount.toFixed(2));
          flag= true;
          break;
        }
      }
      if(!flag){
        this.windowWiseAmount.push(this.paymentData.data[i]);
      }
    }
    var bal = this.balance;
    for(var k=0;k<this.windowWiseAmount.length;k++){
      if(bal>0){
        if(bal >= this.windowWiseAmount[k].amount){
          bal -= this.windowWiseAmount[k].amount
        }
        else{
          this.windowWiseAmount[k].amount = bal;
          bal=0;
        }
      }
      else{
        this.windowWiseAmount.splice(k, (this.windowWiseAmount.length - k));
        break;
      }
    }
    let req = {
      "h_sponsor_id": this.sponsor_details.id,
      "h_member_id": this.memberData.member_id,
      "h_bit_type": "PAYMENT_WITH_POINTS",
      "h_bit_amount": this.balance,
      "h_bit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z"),
      "h_bit_category": "ACCRUAL",
      "h_sponsor_id_text": this.sponsor_details.name,
      "h_program_id": this.h_program_id,
      "h_bit_currency": this.invoiceCurrency,
      "h_bit_source_generated_id":this.confirmation_number,
      "room_number": this.room,
      "collegue_id": this.user,
      "h_start_date": this.arrivalDate,
      "h_end_date": this.departureDate,
      "h_bit_source": 'PMS',

    };
    let payment_window = {};
    for(var i=0;i<this.windowWiseAmount.length;i++){
      payment_window[this.windowWiseAmount[i].window_number] = this.windowWiseAmount[i].amount;
    }
    this.settlePayments = {
      "resort": this.resort,
      "resv_name_id": this.reservation_id,
      "redemption_bit": req,
      "window_amount": payment_window
    };
    this.doPayment();
  }

  doPayment() {
    this.open(this.modaloader,'modalloader');
    this.memberService
      .payWithPoints(this.settlePayments)
      .then((data: any) => {
        this.paymentconfirmation = false;
        this.closeloader();
      })
      .catch(error => {
        this.closeloader();
        this.error = true;
        if(error.error && error.error.message){
          this.errorMsg = error.error.message;
        }
        else{
          this.errorMsg = "Payment failed";
        }
      });
  }

  redeemOffer() {
    let req = {
      "h_bit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z"),
      "h_sponsor_id": this.sponsor_details.id,
      "h_bit_category": "REDEMPTION",
      "offer_id": this.offerData.offerID,
      "h_program_id": this.h_program_id,
      "h_member_id": this.memberData.member_id,
    };
    this.open(this.modaloader,'modalloader');
    this.memberService
      .createBit(req)
      .then((data: any) => {
        this.closeloader();
        this.bitData = data;
        if(data.assigned_privileges.length>0){
          this.rewardIDs += data.assigned_privileges[0].privilege_code;
          for(var i=1;i<data.assigned_privileges.length;i++){
            this.rewardIDs += ', ';
            this.rewardIDs += data.assigned_privileges[i].privilege_code;
          }
        }
        this.paymentconfirmation = false;
      })
      .catch(error => {
        this.AwardConfirmation = false;
        this.paymentconfirmation = false;
        this.redemption = true;
        this.closeloader();
        this.error = true;
        this.errorMsg = error.error.message;
      });
  }

  availOffer(){
    let req = {
      "h_bit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z"),
      "h_sponsor_id": this.sponsor_details.id,
      "h_bit_category": "AVAILMENT",
      "h_program_id": this.h_program_id,
      "h_location":this.consumeLocation,
      "h_comment":this.userNotes,
      "h_bit_source_generated_id":this.invoiceNum,
      "h_unique_privileges": [this.offerData.privilegeCode]
    };
    this.open(this.modaloader,'modalloader');
    this.memberService
      .createBit(req)
      .then((data: any) => {
        this.closeloader();
        this.confirmationmodal = false;
        this.bitData = data;
      })
      .catch(error => {
        if(!this.confirmationmodal){
          return;
        }
        this.closeloader();
        this.error = true;
        this.errorMsg = error.error.message;
      });
  }

  showConfirmation(){
    this.consumption = false;
    this.confirmationmodal = true;
  }
}
