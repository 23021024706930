import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JwtInterceptor, ErrorInterceptor } from '../helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NumbersonlyDirective } from './numbersonly.directive';
import { TwoDigitDecimaNumberDirective } from './twoDecimals.directive';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';


import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { EnrolmentComponent } from './enrolment/enrolment.component';
import { PaywithpointsComponent } from './paywithpoints/paywithpoints.component';
import { PaymentconfirmationComponent } from './paymentconfirmation/paymentconfirmation.component';
import { ConsumerewardComponent } from './consumereward/consumereward.component';
import { InvitationconfirmationComponent } from './invitationconfirmation/invitationconfirmation.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SuccessComponent } from './success/success.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    EnrolmentComponent,
    NumbersonlyDirective,
    TwoDigitDecimaNumberDirective,
    PaywithpointsComponent,
    PaymentconfirmationComponent,
    ConsumerewardComponent,
    InvitationconfirmationComponent,
    LoaderComponent,
    LoginComponent,
    HomeComponent,
    SuccessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    Ng2FlatpickrModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
