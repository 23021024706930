// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiendpoint:{
		url:'https://jumeirah.gravty.me/',
		version:'v1/',
		endpoint:'jumeirah/'
	},
	gravtyendpoint:{
		url:'https://jumeirah.gravty.me/',
		version:'v1/',
		endpoint:'bolapi/'
	},
	h_program_id : '17',
	username: 'pms_user@gravty.io',
	password: 'Testing123',
	xapikey : 'oyCQe3Mf2aaWTqD5OTSTa7uHRRZc9uoB1RN3yODP',
	host : function () {
		return this.apiendpoint.url+ this.apiendpoint.endpoint + this.apiendpoint.version;
	},
	gravtyhost : function () {
		return this.gravtyendpoint.url+ this.gravtyendpoint.endpoint + this.gravtyendpoint.version;
	}
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
