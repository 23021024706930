import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, MemberService } from '../../services';
import { MemberEnrollService } from "../../services/memberenroll.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { Member, member } from "../../models";
import * as _ from "lodash";
import {timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators'; 

import {
	NgbModal
} from "@ng-bootstrap/ng-bootstrap";
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
	@ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
    @ViewChild('modalpaywithpoints')
    private modalpaywithpoints: TemplateRef<any>;
	frequentFlyers: any = [];
	selectFrequentFlyer: boolean = false;
	memberShipInJumeirahOne: boolean = true;
    public memberData: any;
   	error: boolean = false;
	errorMsg: string = "";
	lookups: any = {};
	blue_percent = 0;
	silver_percent = 0;
	gold_percent = 0;
	memberships: any;
	FFPDetails;
	modalRef: any;
	profileId: string = "";
	pms: string = "";
	resort: string = "";
	reservation_id: string = "";
	loader: boolean = false;
	ffp: any;
	countries: any;
	queryParams : any ;
	sponsor_details: any = {
	    name: "",
	    id: null,
	    address: ''
	};
	pmsAgentName: string = '';
	lines: string = '';
	tokenLoader: boolean = false;
	enrolment_code: string = '';
	pointsIndex: number = 0;
	tierPointsIndex: number = 0;
	loginData: any;
	memberId: string = '';
	FFP_data: any;
	user: any;
  	login_response: any;
  	role_data: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
		private memberService: MemberService,
		private commonService: CommonService,
		private memberEnrollService: MemberEnrollService,
	) { }

	ngOnInit() {
		this.modalService.dismissAll();
		if(window.outerWidth<1440){
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
		}
		this.route.queryParams.subscribe(queryParams => {
			if(this.queryParams==undefined){
				this.queryParams = queryParams;
			}
			else if(this.queryParams!= queryParams){
				location.reload();
			}
		});
		var flag=0;
		scrollTo(0,0);
		this.user = JSON.parse(localStorage.getItem('currentUser'));
      	this.login_response = JSON.parse(localStorage.getItem('login_response'));
      	this.sponsor_details.id = this.login_response.sponsor_id;
      	this.sponsor_details.name = this.login_response.sponsor_name;
		this.memberId = this.commonService.getParam('id').toString();
		let subscription = timer(0, 1000).pipe().subscribe(x=>{
			this.myFunction();
			if(!this.tokenLoader){
				this.open(this.modaloader,'modalloader');
				this.tokenLoader = true;
			}
			this.getRole();
			if(localStorage.getItem('token')!=undefined && flag==0){
				this.close();
				this.memberService.getFrequentFlyer().then((data: any) => {
					this.lookups["ffp"] = data.data;
				});
				this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
					this.lookups["TIER_CLASS"] = result;
					if(this.memberData){
						let index = _.findIndex(this.lookups["TIER_CLASS"], {
							"code": this.memberData.tier_class
						});
						if (index !== -1) {
							this.memberData.tier_class = this.lookups["TIER_CLASS"][index].name;
						}
					}
				});
				this.getMemberData();
				this.sponsorDetails();
				flag=1;
				subscription.unsubscribe();
			}
		})
	}

	myFunction() {
		if(window.outerWidth<1440){
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
		}
		else{
			var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
          	x[0].style.zoom = '100%';
		}
	}

	getRole() {
		this.memberService
			.getRole(this.login_response.role.id)
			.then((data: any) => {
				this.role_data = data;
				var index;
				for(var i=0;i<this.role_data.member_attributes.length;i++){
					index = this.role_data.member_attributes[i].attribute_name.indexOf('. ');
					if(index !== -1){
						this.role_data.member_attributes[i].attribute_name = this.role_data.member_attributes[i].attribute_name.slice(index+2);
					}
				}
			})
			.catch(error => {
				this.showErrorMsg(error);
			});
	}

	viewMemberAttribute(attr) {
		if(!this.role_data){
			return false;
		}
		var index = _.findIndex(this.role_data.member_attributes, {
            "attribute_name": attr
        });
		if(index !== -1){
			 return this.role_data.member_attributes[index].can_view;
		}
		return true;
	}

	logout() {
        localStorage.removeItem('tokenTimeOut');
        localStorage.removeItem('token');
        var urllist = window.location.href.split('/#/');
        var url = urllist[0];
        url += '/#/login';
        window.location.href = url;
    }

	sponsorDetails() {
		this.loginData = JSON.parse(localStorage.getItem('login_response'));
	    this.memberEnrollService
	      	.getSponsorDetailsById(this.loginData.sponsor_id)
	      	.then((data: any) => {
            	this.sponsor_details.name = data[0].name;
        		this.sponsor_details.address = data[0].address;
        		this.sponsor_details.id = data[0].id;
	      });
	}

	addFrequentFlyerMembership() {
		this.selectFrequentFlyer = true;
	}

	cancelFrequentFlyerMembership() {
		this.getMemberData();
		this.selectFrequentFlyer = false;
	}

	gotoEnrolmentPage() {
		var url= window.location.href;
		var path = url.split('/#/');
		localStorage.removeItem('enrolment_code');
		window.location.href= path[0] + '/#/enrolment?member=' + this.memberId;
	}

	gotoHome() {
		var url= window.location.href;
		var path = url.split('/#/');
		window.location.href= path[0];
	}

	open(content, modalclass) {
		this.modalRef = this.modalService.open(content, { windowClass: modalclass });
	}

	close() {
		this.modalRef.close();

	}
	showPopup(modalid, modalclass) {
		this.open(modalid, modalclass)
	}

	addMembership() {
		var object = [];
		for (var i = 0; i < this.memberships.length; i++) {
			var x = this.lookups["ffp"][i].value;
			if (this.memberships[i].selected == true && (this.memberships[i].value != this.lookups["ffp"][i].value || this.memberships[i].membershipName != this.lookups["ffp"][i].membershipName)) {
				if (this.lookups["ffp"][i].value == "" && this.lookups["ffp"][i].membershipName=="") {
					let req = { "ffp_program_name": this.memberships[i].name, 'name':this.memberships[i].membershipName,"ffp_membership_no": this.memberships[i].value, "member_id": this.memberData.member_id, "action":"CREATE" };
					object.push(req);
				}
				else {
					var id;
					for (var j = 0; j < this.frequentFlyers.length; j++) {
						if (this.frequentFlyers[j].ffp_program_name == this.lookups["ffp"][i].name) {
							id = this.frequentFlyers[j].id;
						}
					}
					let req = { "ffp_program_name": this.memberships[i].name,'name':this.memberships[i].membershipName, "ffp_membership_no": this.memberships[i].value, "member_id": this.memberData.member_id, "id": id, "action":"UPDATE" };
					object.push(req);
				}
			}
		}
		if(object.length>0){
			this.loader= true;
			this.open(this.modaloader,'modalloader');
			this.memberService.ffp(object).then((data: any) => {
				this.close();
				this.cancelFrequentFlyerMembership();
			})
			.catch(error => {
				this.close();
				if(!error.error)
					return;
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
		}
		else {
			this.selectFrequentFlyer = false;
		}
	}
	formatAddress(){
		if(this.memberData.country_code){
			this.countryMapping();
		}
		this.memberData.address = '';
		if(this.memberData.address_line1 && this.viewMemberAttribute('Address Line 1')){
			this.memberData.address += this.memberData.address_line1 ;
		}
		if(this.memberData.address_line2 && this.viewMemberAttribute('Address Line 2')){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += ', '
			}
			this.memberData.address += this.memberData.address_line2 ;
		}
		this.lines = this.memberData.address;
		if(this.memberData.city_text && this.viewMemberAttribute('City (text)')){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += '<br/>'
			}
			this.memberData.address += this.memberData.city_text ;
		}
		if(this.memberData.region_name && this.viewMemberAttribute('Region (text)')){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += ', '
			}
			this.memberData.address += this.memberData.region_name ;
		}
		if(this.memberData.country_name && this.viewMemberAttribute('Country')){
			if(this.memberData.address.length>0 && this.memberData.address[this.memberData.address.length-1]!==' '){
				this.memberData.address += '<br/>'
			}
			if(this.viewMemberAttribute('Country')){
				this.memberData.address += this.memberData.country_name ;
			}
		}
		document.getElementById('address').innerHTML = this.memberData.address;
	}
	countryMapping() {
	    if(this.memberData && this.memberData.country_code){
	        for(var i=0;i<this.countries.length;i++){
	            if(this.countries[i].iso_code === this.memberData.country_code){
	            	this.memberData.country_name = this.countries[i].name;
	        	}
	        }
	    }
	}
	getpmsData(profileId, resortId) {
		this.memberShipInJumeirahOne = false;
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.getPmsProfile(profileId, resortId)
			.then((data: any) => {
				this.memberData = data;
				this.memberData.city_text = data.city_name;
				this.memberData.region_name = data.state_prov;
				if(data.ffp_details){
					this.frequentFlyers = data.ffp_details.data;
				}
				this.formatAddress();
				this.close();
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
	}

	formatMemberData(data){
		this.FFPDetails = data["ffp_details"];
		this.frequentFlyers = [];
		this.memberData = data.data;
		this.memberData.city_text =  data.data.extra_data.city_text;
		this.memberData.region_name =  data.data.extra_data.region_text;
		let roledata = timer(0, 100).pipe().subscribe(x=>{
			if(this.role_data){
				this.formatAddress();
				roledata.unsubscribe();
			}
		});
		var balance = this.memberData.balances;
		if(balance!==undefined && balance.length>0){
			for (var x = 0; x < balance.length; x++) {
				if (balance[x].loyalty_account == "Points") {
					localStorage.setItem('pointsIndex',x.toString());
					this.pointsIndex = x;
				}
			}
			this.memberData.balances[this.pointsIndex].balance = parseInt(this.memberData.balances[this.pointsIndex].balance.toFixed(0));
			this.memberData.balances[this.pointsIndex].balance_currency_amount = parseInt(this.memberData.balances[this.pointsIndex].balance_currency_amount.toFixed(0));
			var tier_point_balance;
			for (var i = 0; i < balance.length; i++) {
				if (balance[i].loyalty_account == "Tier Points") {
					balance[i].balance = parseInt(balance[i].balance.toFixed(0));
					tier_point_balance = balance[i].balance;
					this.tierPointsIndex = i;
				}
			}
			if(tier_point_balance==undefined){
				tier_point_balance = 0;
			}
			if (this.memberData.tier_class === 'JM') {
				this.silver_percent = 0;
				this.gold_percent = 0;
				this.blue_percent = (tier_point_balance * 100) / 5000;
				if(this.blue_percent>100){
					this.blue_percent = 100;
				}
			}
			else if (this.memberData.tier_class === 'JS') {
				this.gold_percent = 0;
				this.blue_percent = 100;
				this.silver_percent = ((tier_point_balance) * 100) / 30000;
				this.silver_percent = parseFloat(this.silver_percent.toFixed(2));
				if(this.silver_percent>100){
					this.silver_percent = 100;
				}
			}
			else if (this.memberData.tier_class === 'JG') {
				this.silver_percent = 100;
				this.blue_percent = 100;
				this.gold_percent = ((tier_point_balance ) * 100) / 90000;
				this.gold_percent = parseFloat(this.gold_percent.toFixed(2));
				if(this.gold_percent>100){
					this.gold_percent = 100;
				}
			}
			else if(this.memberData.tier_class === 'JP'){
				this.silver_percent = 100;
				this.blue_percent = 100;
				this.gold_percent = 100;
			}
			document.getElementById("blue").style.width = this.blue_percent + "%";
			document.getElementById("silver").style.width = this.silver_percent + "%";
			document.getElementById("gold").style.width = this.gold_percent + "%";
			let index = _.findIndex(this.lookups["TIER_CLASS"], {
				"code": this.memberData.tier_class
			});
			if (index !== -1) {
				this.memberData.tier_class = this.lookups["TIER_CLASS"][index].name;
			}
	    }
		this.frequentFlyers = data.ffp_details;
		for (var i = 0; i < this.lookups["ffp"].length; i++) {
			this.lookups["ffp"][i]['selected'] = false;
			this.lookups["ffp"][i]['value'] = "";
			this.lookups["ffp"][i]['membershipName'] = "";
			for (var j = 0; j < this.frequentFlyers.length; j++) {
				if (this.lookups["ffp"][i].name === this.frequentFlyers[j].ffp_program_name) {
					this.lookups["ffp"][i]['selected'] = true;
					this.lookups["ffp"][i]['value'] = this.frequentFlyers[j].ffp_membership_no;
					this.lookups["ffp"][i]['membershipName'] = this.frequentFlyers[j].name;
					this.lookups["ffp"][i].id = this.frequentFlyers[j].id;
				}

			}

		}
		let copy = JSON.parse(JSON.stringify(this.lookups["ffp"]))
		this.memberships = copy;
	}

	getMemberFFP() {
		this.memberService
			.getMemberFFP(this.memberId)
			.then((data: any) => {
				this.FFP_data = data.data;
			})
	}

	changePath(path){
    	window.location.href = window.location.href.split('member')[0] + path;
    }

	getMemberData() {
		this.getMemberFFP();
		this.memberEnrollService
      		.getCountires()
            .then((data: any) => {
        		if(this.memberData){
           		this.close();
        	}      
        	this.countries = data;
        	this.countries.sort((a, b) => (a.name > b.name) ? 1 : -1);
        	this.countryMapping();
      	});
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.getMemberDataWithId(this.memberId)
			.then((data: any) => {
				this.close();
				var obj = {
					'data': [],
					'ffp_details': []
				};
				obj.data = data.data;
				let subscription = timer(0, 100).pipe().subscribe(x=>{
					if(this.countries && this.FFP_data){
						this.formatMemberData(obj);
						subscription.unsubscribe();
					}
				});
				obj.ffp_details = this.FFP_data;
				
			})
			.catch(error => {
				this.close();
				if(!error.error)
					return;
				this.commonService.showErrorMsg(error.error, this.error, this.errorMsg);
			});
	}
	delete_ffp(x) {
		this.loader = true;
		this.close();
		if(x==1){
			var obj = [];
			let req = {"id": this.ffp.id, "action": "DELETE" };
			obj.push(req);
			this.loader = true;
			this.open(this.modaloader,'modalloader');
			this.memberService.ffp(obj).then((data: any) => {
				this.ffp.selected = false;
				this.loader = false;
				this.close();
				this.getMemberData();
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
		}
	}
	setFrequentFlyer(ffp) {
		this.ffp =ffp;
		if (ffp.selected) {
			for(var i=0;i<this.lookups['ffp'].length;i++){
				if(this.ffp.name==this.lookups['ffp'][i].name){
					if(!this.lookups['ffp'][i]['selected']){
						ffp.selected= false;
					}
					else{
						this.loader=false;
						return;
						this.open(this.modaloader,'modalDeleteConfirmation');
					}
				}
		}
		}
		else{
			ffp.selected = true;
		}
	}
	showErrorMsg(error) {
		this.error = true;
		this.commonService.scrollTop();
		this.errorMsg = this.commonService.showCommonErrorMsg(error);
	}
	resendInvitation(email,modalinvitationconfirmation){
		let _self = this;
		let request = {"email":email};
		this.loader = true;
		this.open(this.modaloader,'modalloader');
		this.memberService
			.resendInvitation(request)
			.then((data: any) => {
				this.close();
				this.showPopup(modalinvitationconfirmation,'modalinvitationconfirmation');
				setTimeout(function(){ _self.close(); }, 4000);
			})
			.catch(error => {
				this.close();
				this.showErrorMsg(error);
			});
	}
}
