import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private webService: WebService,
  ) { }
  private host = environment.host();
  private gravtyhost = environment.gravtyhost();
  getMemberData(profileId, resortId) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let requestUrl = this.host + "program/"+ tenantInfo.id + "/gravty-member/?resort=" + resortId + "&profile=" + profileId;
    return this.webService.getAPI(requestUrl, false);
  }
  getPmsProfile(profileId, resortId) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    let requestUrl = this.host + "program/"+ tenantInfo.id + "/pms-profile/?resort=" + resortId + "&profile=" + profileId;
    return this.webService.getAPI(requestUrl, false);
  }
  getFrequentFlyer() {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_programlist/";
    return this.webService.getAPI(requestUrl, false);
  }
  ffp(request) {
    let requestUrl = this.host + "ffp/";
    return this.webService.postAPI(requestUrl,request,false);
  }
  setFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  updateFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/" + request.id + "/";
    return this.webService.putAPI(requestUrl, request, false);
  }
  deleteFrequentFlyer(request) {
    let requestUrl = this.gravtyhost + "entity-data/members/frequentflyer_program/" + request.id + "/";
    return this.webService.deleteAPI(requestUrl, false);
  }

  getInvoiceDetails(resv_id,resort) {
    let requestUrl = this.host + "pms-invoices/?resv_name_id="+resv_id+"&resort="+resort;
    return this.webService.getAPI(requestUrl, false);
  }

  getPointsConversionList() {
    let requestUrl = this.gravtyhost + "point-conversion-rates/list/";
    return this.webService.getAPI(requestUrl, false);
  }

  createBit(request) {
    let requestUrl = this.gravtyhost + "bit/";
    return this.webService.postAPI(requestUrl, request, false);
  }

  getSponsorLocations(id) {
    let requestUrl = this.gravtyhost + 'sponsors/' + id +'/locations/?active=True&page_size=1000';
    return this.webService.getAPI(requestUrl, false);
  }

  payWithPoints(request) {
    let requestUrl = this.host + "pms-payment/";
    return this.webService.postAPI(requestUrl, request, false);
  }

  getAwardOffers(memberId) {
    let requestUrl = this.gravtyhost + "members/offers/"+memberId+"/?offerType=award&ordering=-created_ts";
    return this.webService.getAPI(requestUrl, false);
  }

  getAllSponsorLocations() {
    let requestUrl = this.gravtyhost + 'locations/';
    return this.webService.getAPI(requestUrl, false);
  }

  getPrivileges( memberId) {
    let requestUrl = this.gravtyhost + "members/privileges/" + memberId + '/?status=AVAILABLE';
    return this.webService.getAPI(requestUrl, false);
  }
  getBItDetail(memberId,bitID) {
    let requestUrl = this.gravtyhost + "bits/?member_id=" + memberId + "&bit_id=" + bitID;
    return this.webService.getAPI(requestUrl, false);
  }
  resendInvitation(request) {
    let requestUrl = this.gravtyhost + "members/send-otp/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  getMemberList(firstName, lastName, memberId, email) {
    var params = '?';
    if(firstName && firstName !== ''){
      params += '&first_name=' + firstName;
    }
    if(lastName && lastName !== ''){
      params += '&last_name=' + lastName;
    }
    if(memberId && memberId !== ''){
      params += '&member_id=' + memberId;
    }
    if(email && email !== ''){
      params += '&email=' + email;
    }
    let requestUrl = this.gravtyhost + 'members/list/' + params;
    return this.webService.getAPI(requestUrl, true);
  }
  getFilteredMemberList(requestUrl){
    return this.webService.getAPI(requestUrl, true);
  }
  getMemberWithId(id){
    let requestUrl = this.gravtyhost + 'members/list/?member_id=' + id;
    return this.webService.getAPI(requestUrl, false);
  }
  getMemberDataWithId(id){
    let requestUrl = this.gravtyhost + 'members/data/' + id;
    return this.webService.getAPI(requestUrl, false);
  }
  getMemberFFP(id){
    let requestUrl = this.gravtyhost + 'entity-data/members/frequentflyer_program/?filter{member_id}=' + id;
    return this.webService.getAPI(requestUrl, false);
  }
  getRole(id){
    let requestUrl = this.gravtyhost + 'roles/' + id;
    return this.webService.getAPI(requestUrl, false);
  }
  getSponsorDetailsById(id) {
    let requestUrl = this.gravtyhost + "sponsors/"+id+'/';
    return this.webService.getAPI(requestUrl, false);
  }
}
