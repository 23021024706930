import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AppComponent } from "../app.component";
import { ActivatedRoute } from '@angular/router';
import { MemberEnrollService } from "../../services/memberenroll.service";
import { WebService } from "../../services/web.service";
import { CommonService, MemberService } from '../../services';
import { environment } from "../../environments/environment";
import * as moment from 'moment-timezone';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormsModule,
  NgForm
} from "@angular/forms";
import {
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators'; 
import { FlatpickrOptions } from 'ng2-flatpickr';
@Component({
  selector: 'app-enrolment',
  templateUrl: './enrolment.component.html',
  styleUrls: ['./enrolment.component.less']
})
export class EnrolmentComponent implements OnInit {
  @ViewChild('modalloader')
    private modaloader: TemplateRef<any>;
  @ViewChild('startPicker') pickerStart;
  enrolmentDetails: boolean = false;
  emailpattern = "^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9-\+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$";
  enroled: any;
  member_exists: boolean = false;
  lookups: any = {};
  account_details: any = {
    user: {
      email: "",
      first_name: "",
      last_name: ""
    },
    extra_data: {
      work_number: "",
      home_number: "",
      enrollment_code: "",
      region_text: "",
      city_text: "",
      zipcode_text: "",
      colleague_id: ''
    },
    middle_name: "",
    date_of_birth: null,
    mobile: "",
    address_line1: "",
    address_line2: "",
    country: "",
    enrolling_sponsor: null,
    profile_id: '',
    salutation: '',
    resort: '',
    gender: null,
    nationality: null,
    enrollment_channel: "POS",
    ffp_details: []
  };
  countries: any;
  enrolment_hotel: string;
  states: any = null;
  cities: any = null;
  country_name: string = "";
  city_name: string = "";
  state_name: string = "";
  firstname_touched: boolean = false;
  firstname_focus: boolean = false;
  lastname_touched: boolean = false;
  lastname_focus: boolean = false;
  email_touched: boolean = false;
  email_focus: boolean = false;
  mobile_touched: boolean = false;
  mobile_focus: boolean = false;
  error_msg: string = null;
  profileId: string = '0';
  resort: string = '';
  modalRef: any;
  salutations: any;
  memberData: any;
  reservation_id: string = '';
  location_address: string = '';
  verified = 'No';
  pmsAgentName: string = '';
  sponsor_name: string = '';
  form: FormGroup;
  flatPickerInput: string = '';
  startOptions: FlatpickrOptions = {
    dateFormat: 'd M Y',
    defaultDate: null,
    maxDate: new Date(Date.now()),
  };
  memberKeys: any = [];
  extraDataKeys: any = [];
  memberId: string = '';
  maxDate = '';
  loaded: boolean = false;
  queryParams : any ;
  loginData: any;
  sponsor_details: any = {
      name: "",
      id: null,
      address: ''
  };
  user: any;
  login_response: any;
  h_program_id = null;
  constructor(
    private route: ActivatedRoute, private webService: WebService, private modalService: NgbModal, private formBuilder: FormBuilder, private memberEnrollService: MemberEnrollService, private memberService: MemberService, private commonService: CommonService,
  ) { }
  private gravtyhost = environment.gravtyhost();
  ngOnInit() {
    this.modalService.dismissAll();
    this.route.queryParams.subscribe(queryParams => {
      if(this.queryParams==undefined){
        this.queryParams = queryParams;
      }
      else if(this.queryParams!= queryParams){
        location.reload();
      }
    });
    this.memberKeys = Object.keys(this.account_details);
    this.extraDataKeys = Object.keys(this.account_details.extra_data);
    var today = moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DD");
    var year = today.substring(0, 4);
    var minYear = (parseInt(today.substring(0, 4))-18).toString();
    this.maxDate = today.replace(year, minYear);
    this.form = this.formBuilder.group({
      start: null,
    });
    if(window.outerWidth<1440){
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
    }
    this.memberId = this.commonService.getParam('member').toString();
    if(this.memberId!=='0'){
      this.memberService.getMemberWithId(this.memberId)
        .then((data: any) => {
          if(data.length!==0){
            this.memberData = data[0];
            this.account_details = this.memberData;
            if(this.account_details.date_of_birth && this.account_details.date_of_birth.length>0){
              this.startOptions.defaultDate = this.formatDate(this.account_details.date_of_birth, false);
            }
          }
          if(this.countries){
            this.close();
            this.loaded = true;
          }
        });
    }
    this.tenantInfoCheck();
    this.commonService.getData("lookups", "SALUTATION_LIST").subscribe(result => {
      this.salutations = result;
    });
    this.memberEnrollService
      .getCountires()
      .then((data: any) => {
        this.myFunction()
        if(this.memberData || this.memberId==='0'){
           this.close();
           this.loaded = true;
        }      
        this.countries = data;
        this.countries.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
    this.loginData = JSON.parse(localStorage.getItem('login_response'));
    this.memberEnrollService
      .getSponsorDetailsById(this.loginData.sponsor_id)
      .then((data: any) => {
          this.enrolment_hotel = data[0].name;
          this.location_address = data[0].address;
          this.account_details.enrolling_sponsor = data[0].id;
          if(localStorage.getItem('enrolment_code')!= undefined || localStorage.getItem('enrolment_code')!= null){
            this.account_details.extra_data.enrollment_code = localStorage.getItem('enrolment_code');
          }
          else{
            this.account_details.extra_data.enrollment_code = data[0].short_name;
          }

        });

      this.commonService.getData("lookups", "TIER_CLASS").subscribe(result => {
        this.lookups["TIER_CLASS"] = result;
      });

      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.login_response = JSON.parse(localStorage.getItem('login_response'));
      this.sponsor_details.id = this.login_response.sponsor_id;
      this.sponsor_details.name = this.login_response.sponsor_name;

      let subscription = timer(0, 10).pipe().subscribe(x=>{
          this.open(this.modaloader,'modalloader');
          subscription.unsubscribe();
      })

  }

  myFunction() {
    if(window.outerWidth<1440){
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = (window.outerWidth/14.40).toString() + '%';
    }
    else{
      var x = document.getElementsByTagName("BODY") as HTMLCollectionOf<HTMLElement>;
      x[0].style.zoom = '100%';
    }
  }

  tenantInfoCheck() {
    if(localStorage.getItem('tenantInfo')==undefined || localStorage.getItem('tenantInfo')==null){
      var tenantInfoUrl = this.gravtyhost + 'tenants/basic-info/';
      this.webService.getAPI(tenantInfoUrl, false).then((data: any) => {
        localStorage.setItem('tenantInfo',JSON.stringify(data));
        this.tenantInfoCheck();
      });
    }
    else{
      var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
      if(tenantInfo){
        this.h_program_id = tenantInfo.id;
      }
    }
  }

  formatDate(d, validation){
    var date = new Date(d);
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if(validation){
      return date.getFullYear() + '-' +("0" + (date.getMonth() + 1)).slice(-2)+'-'+ ("0" + date.getDate()).slice(-2);
    }
    else{
      return date.getDate()+' '+monthNames[date.getMonth()]+' '+ date.getFullYear();
    }
  }

  open(content, modalclass) {
    this.modalRef = this.modalService.open(content, { windowClass: modalclass });
  }

  close() {
    this.modalRef.close();
  }

  logout() {
    localStorage.removeItem('tokenTimeOut');
    localStorage.removeItem('token');
    var urllist = window.location.href.split('/#/');
    var url = urllist[0];
    url += '/#/login';
    window.location.href = url;
  }

  countryMapping() {
    if(this.memberData && this.memberData.country_code){
      for(var i=0;i<this.countries.length;i++){
        if(this.countries[i].iso_code === this.memberData.country_code){
          this.account_details.country = this.countries[i].id;
        }
      }
    }
  }

  firstnameFocus() {
    this.firstname_touched = true;
    this.firstname_focus = true;
    document.getElementById("firstname").focus();
  }
  firstnameFocusOut() {
    this.firstname_focus = false;
  }
  lastnameFocus() {
    this.lastname_touched = true;
    this.lastname_focus = true;
    document.getElementById("lastname").focus();
  }
  lastnameFocusOut() {
    this.lastname_focus = false;
  }
  emailFocus() {
    this.member_exists = false;
    this.email_touched = true;
    this.email_focus = true;
    document.getElementById("email").focus();
  }
  emailFocusOut() {
    this.email_focus = false;
  }
  mobileFocus() {
    this.mobile_touched = true;
    this.mobile_focus = true;
    document.getElementById("mobile").focus();
  }
  mobileFocusOut() {
    this.mobile_focus = false;
  }
  validate(enrollmentform) {
    this.firstname_touched = true;
    this.lastname_touched = true;
    this.email_touched = true;
    this.mobile_touched = true;
    if (enrollmentform)
      this.enroll();
  }
  getCities() {
    var state = (<HTMLInputElement>document.getElementById('state')).value;
    this.account_details.region = state;
    this.account_details.city = "";
    this.open(this.modaloader,'modalloader');
    this.memberEnrollService
      .getCitiesList(state)
      .then((data: any) => {
        this.close();
        this.cities = data;
        this.cities.sort((a, b) => (a.name > b.name) ? 1 : -1);
      })
  }
  
  getStates() {
    var country = (<HTMLInputElement>document.getElementById('country')).value;
    this.account_details.country = country;
    return;
    this.account_details.region = "";
    this.account_details.city = "";
    this.cities= null;
    this.open(this.modaloader,'modalloader');
    this.memberEnrollService
      .getStatesList(country)
      .then((data: any) => {
        this.close();
        this.states = data;
        this.states.sort((a, b) => (a.name > b.name) ? 1 : -1);
      });
  }

  rediectToProfilePage(){
    var url = window.location.href;
    var domain = url.split('enrolment')[0];
    window.location.href = domain;
  }

  enroll() {
    if (this.form.value.start) {
      var x= this.form.value.start[0];
      this.account_details.date_of_birth = x.getFullYear()+'-'+(x.getMonth()+1)+'-'+((x.getDate()<10)?"0"+x.getDate():x.getDate());
    }
    this.error_msg = null;
    let req = {};
    if(this.account_details.membership_stage === 'FFP Member'){
      for(var it=0;it<this.memberKeys.length;it++){
        req[this.memberKeys[it]] = this.account_details[this.memberKeys[it]];
      }
      req['extra_data']={};
      for(var ix=0;ix<this.extraDataKeys.length;ix++) {
        if(this.account_details['extra_data'][this.extraDataKeys[ix]]){
          req['extra_data'][this.extraDataKeys[ix]] = this.account_details['extra_data'][this.extraDataKeys[ix]];
        }
      }
      delete req['enrolling_sponsor'];
      delete req['enrollment_channel'];
      req['membership_stage'] = 'FFP Member';
      req['member_id'] =  this.account_details.member_id;
      req['ffp_details'] = [];

      var obj = {
        'h_bit_category': "SERVICE",
        "h_bit_date": moment(new Date(), 'DD/MM/YYYY h:mm A').format("YYYY-MM-DDTHH:mm") + "" + moment().tz('Asia/Dubai').format("Z"),
        'h_bit_type': "MEMBERSHIP_CHANGE",
        'h_member_id': this.account_details.member_id,
        'h_membership_stage_id': 10,
        'h_program_id': this.h_program_id,
        'h_sponsor_id': this.sponsor_details.id
      }
      this.open(this.modaloader,'modalloader');
      this.memberService
      .createBit(obj)
      .then(() => {
        this.enrollApi(req);
      })
      .catch(() => {
        this.close();
      })
    }
    else{
      req = this.account_details;
      this.open(this.modaloader,'modalloader');
      this.enrollApi(req);
    }
    
  }

  enrollApi(req){
    this.memberEnrollService
      .memberEnrollment(req)
      .then((data: any) => {
        this.close();
        // if (data.status === 400) {
        //   this.member_exists = true;
        // }
        if (data.status === 201 || data.status === 200) {

          for(var x=0;x<this.lookups["TIER_CLASS"].length;x++){
            if(this.lookups["TIER_CLASS"][x].code === data.body.tier_class){
              data.body.tier_name = this.lookups["TIER_CLASS"][x].name;
              break;
            }
          }

          this.enroled = data.body;
          if(this.enroled.date_of_birth){
            this.enroled.date_of_birth += 'T00:00:00+00:00';
          }
          if(this.enroled.date_of_joining){
            this.enroled.date_of_joining += 'T00:00:00+00:00';
          }
          if(data.body.extra_data && data.body.extra_data['t&c']){
            this.verified = data.body.extra_data['t&c'];
          }
          for (var i = 0; i < this.countries.length; i++) {
            if (this.countries[i].id == this.enroled.country)
              this.country_name = this.countries[i].name;
          }
          if (this.states)
            for (var i = 0; i < this.states.length; i++) {
              if (this.states[i].id == this.enroled.region)
                this.state_name = this.states[i].name;
            }
          if (this.cities)
            for (var i = 0; i < this.cities.length; i++) {
              if (this.cities[i].id == this.enroled.city)
                this.city_name = this.cities[i].name;
            }
          this.enrolmentDetails = true;

        }
      })
      .catch(error => {
        this.close();
        if(error.error.code === 'member_exists_unvalidated' || error.error.code === 'member_exists'){
          this.member_exists = true; 
        }
        else if (error.error){
          if (error.error.message)
            this.error_msg = error.error.message;

          window.scrollTo(0, 0);
          if (error.error.email) {
            this.error_msg = error.error.email.message;
          }
          if (error.error.mobile) {
            this.error_msg = error.error.mobile[0].message;
          }
        }
      })
  }
}
