import { Injectable } from "@angular/core";
import { WebService } from "../services/web.service";
import { environment } from "./../environments/environment";

@Injectable({
  providedIn: "root"
})
export class MemberEnrollService {
  constructor(private webService: WebService) {}
    private host = environment.host();
    private gravityhost = environment.gravtyhost();
  checkMemberExist(request) {
    let requestUrl = this.host + "members/exists/";
    return this.webService.postAPI(requestUrl, request, false);
  }
  memberEnrollment(request) {
    var tenantInfo = JSON.parse(localStorage.getItem('tenantInfo'));
    if(request.membership_stage === 'FFP Member'){
      let requestUrl = this.gravityhost + "members/update/" + request.member_id;
      return this.webService.patchAPI(requestUrl, request, true);
    }
    else{
      let requestUrl = this.gravityhost + "members/";
      requestUrl = requestUrl.replace('v1','v2');
      return this.webService.postAPI(requestUrl, request, true);
    }
    
  }

  PmsIdEnrollment(request) {
    let requestUrl = this.host + "DynamoDBPostTransaction/";
    return this.webService.postAPI(requestUrl, request, true);
  }
  getCountires = function() {
    var requestUrl = this.gravityhost + 'countries/';
  return this.webService.getAPI(requestUrl);
  };
  getSponsorDetails(id) {
    let requestUrl = this.gravityhost + "sponsors/"+id+'/';
    return this.webService.getAPI(requestUrl, false);
  }
  getSponsorDetailsByShortName(name) {
    let requestUrl = this.gravityhost + "sponsors/?short_name="+name+'&status=A';
    return this.webService.getAPI(requestUrl, false);
  }
  getSponsorDetailsById(id) {
    let requestUrl = this.gravityhost + "sponsors/?id="+id+'&status=A';
    return this.webService.getAPI(requestUrl, false);
  }
  getStatesList = function(countryId) {
    var requestUrl = this.gravityhost + 'regions/' + countryId + '/';
    return this.webService.getAPI(requestUrl);
  };
  getStatesListWithPageNo = function(countryId, pageno) {
    var requestUrl = this.gravityhost + 'regions/' + countryId + '/?page=' + pageno;
    return this.webService.getAPI(requestUrl);
  };
  getCitiesList = function(statesId) {
    var requestUrl = this.gravityhost + 'cities/' + statesId + '/';
    return this.webService.getAPI(requestUrl);
  };
}